const TABS_ITEMS = document.querySelectorAll('.tabs-item');
const TABS = document.querySelectorAll('.tab');
if (TABS_ITEMS && TABS) {
  let clearActive = () => {
    TABS_ITEMS.forEach(elem => {
      elem.classList.remove('-is-active');
    })

    TABS.forEach(elem => {
      elem.classList.remove('-is-active');
    })

  }
  TABS_ITEMS.forEach((elem, index) => {
    elem.addEventListener('click', () => {
      clearActive();
      elem.classList.add('-is-active');
      TABS[index].classList.add('-is-active');
    })
  })
}