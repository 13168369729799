const header = document.querySelector('.header');


if (header) {
  let burger = header.querySelector('.header__burger');
  let mibileMenu = document.querySelector('.mobile-nav');
  let close = mibileMenu.querySelector('.mobile-nav__close ');
  let links = mibileMenu.querySelectorAll('.nav__link');

  burger.addEventListener('click', () => {
    mibileMenu.classList.add('-is-active');
  })

  close.addEventListener('click', () => {
    mibileMenu.classList.remove('-is-active');
  });
  links.forEach(elem => {
    elem.addEventListener('click', () => {
      mibileMenu.classList.remove('-is-active');
    });
  })

  // document.addEventListener('scroll', () => {
  //   const posTop = document.body.getBoundingClientRect().top;
  //   if (-posTop > 200) {
  //     header.classList.add('--scroll');
  //   } else {
  //     header.classList.remove('--scroll');
  //   }
  // })
}