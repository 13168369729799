
import ymaps from 'ymaps';
const MAP = document.querySelector('#map');
if (MAP) {
  window.onload = function () {
    ymaps
      .load('https://api-maps.yandex.ru/2.1/?apikey=17c2ce43-9cd7-4184-94de-a46354f7f503&lang=ru_RU')
      .then(maps => {
        const myMap = new maps.Map('map', {
          center: [59.999723, 30.260379],
          zoom: 13
        });
        let myPlacemark = new maps.Placemark([59.999723, 30.260379], {}, {
          iconLayout: 'default#image',
          iconImageHref: '../img/marker.svg',
          iconImageSize: [78, 78],
          iconImageOffset: [-40, -70]
        });
        myMap.behaviors.disable('scrollZoom');
        myMap.geoObjects.add(myPlacemark);
      })
  }
}


// map points

const mapMarkers = document.querySelector('.map');

if (mapMarkers) {
  let mapPic = mapMarkers.querySelector('.map__pic');
  let next = mapMarkers.querySelector('.next');
  let prev = mapMarkers.querySelector('.prev');
  let pointsContainer = mapMarkers.querySelector('.pointsContainer');
  next.addEventListener('click', () => {
    mapPic.classList.add('-is-active');
  });
  prev.addEventListener('click', () => {
    mapPic.classList.remove('-is-active');
  });

  let mapPoints = document.querySelectorAll('.map-point');
  if (mapPoints) {
    let elemUpdate = () => {
      mapPoints = document.querySelectorAll('.map-point');
      setTimeout(() => {
        elemInit();
      }, 100)
    }

    let elemInit = () => {
      mapPoints.forEach(elem => {
        let rectPoint = elem.querySelector('rect');
        rectPoint.addEventListener('click', () => {
          let pointActive = document.querySelector('.map-point.active');
          if (pointActive) pointActive.classList.remove('active');
          let newPoint = `<g class="map-point active">${elem.innerHTML}</g>`
          elem.remove();
          pointsContainer.innerHTML = pointsContainer.innerHTML + newPoint;
          elemUpdate();
        })
      })
    }
    elemInit();
  }
}
