const accardeonItem = document.querySelectorAll('.activities__item');
if (accardeonItem) {
  accardeonItem.forEach(elem => {
    let accardeonMain = elem.querySelector('.activities__mobile-main');
    let accardeonGroup = elem.querySelector('.activities__group');
    accardeonMain.addEventListener('click', () => {
      if (!accardeonMain.classList.contains('-is-active')) {
        accardeonMain.classList.add('-is-active');
        accardeonGroup.classList.add('-is-active');
      } else {
        accardeonMain.classList.remove('-is-active');
        accardeonGroup.classList.remove('-is-active');
      }
    })

  })
}