const SEARCH = document.querySelector('.search');


if (SEARCH) {
  let searchInput = SEARCH.querySelector('.search__input input');
  let searchIco = SEARCH.querySelector('.search__ico');
  let searchTrigger = SEARCH.querySelector('.header__search-trigger');

  searchTrigger.addEventListener('click', () => {
    SEARCH.classList.add('-is-active');
  });

  searchInput.addEventListener('focus', () => {
    searchIco.classList.add('-is-active');
  });

  searchInput.addEventListener('blur', () => {
    searchIco.classList.remove('-is-active');
    SEARCH.classList.remove('-is-active');
    searchInput.value = "";
  });
}


const FORM = document.querySelector('.form');

if (FORM) {
  let submit = FORM.querySelector('.form__submit');
  let inputs = FORM.querySelectorAll('.input.requared');
  submit.addEventListener('click', (e) => {
    e.preventDefault();
    inputs.forEach(elem => {
      let inputElem = elem.querySelector('input');
      let inputError = elem.querySelector('.input__error');
      if (inputElem.value < 1) {
        inputError.classList.add('-is-active');
      } else {
        inputError.classList.remove('-is-active');
      }
    })
  })
}