import Swiper, { Navigation, Pagination } from 'swiper';
const projectSlidersParent = document.querySelectorAll('.tab');

if (projectSlidersParent) {
  projectSlidersParent.forEach(elem => {
    const sliderNumberActive = elem.querySelector('.slider-active');
    const sliderNumberAll = elem.querySelector('.slider-all');
    let slider = elem.querySelector('.project-slider');
    let swiper = new Swiper(slider, {

      modules: [Navigation, Pagination],
      loop: false,
      slidesPerView: 1,
      spaceBetween: 0,
      on: {
        slideChange: function () {
          const index_currentSlide = swiper.realIndex;
          if (sliderNumberActive) {
            sliderNumberActive.innerHTML = "";
            sliderNumberActive.innerHTML = `${index_currentSlide + 1}`;
          }
        },
        init: function () {
          if (sliderNumberAll) {
            let allSlides = elem.querySelectorAll('.swiper-slide');
            sliderNumberAll.innerHTML = "";
            sliderNumberAll.innerHTML = ` / ${allSlides.length}`
          }
        }
      }
    });
    const NEXT = elem.querySelector('.next');
    const PREV = elem.querySelector('.prev');

    if (NEXT, PREV) {
      NEXT.addEventListener('click', () => {
        swiper.slideNext();
      })
      PREV.addEventListener('click', () => {
        swiper.slidePrev();
      })
    }

  })
}

const mainSlider = document.querySelector('.main__slider');
if (mainSlider) {
  const mainNav = document.querySelector('.slider-nav--main');
  const sliderNumberActive = mainNav.querySelector('.slider-active');
  const sliderNumberAll = mainNav.querySelector('.slider-all');
  let mainSwiper = new Swiper('.main__slider', {

    modules: [Navigation, Pagination],
    loop: false,
    slidesPerView: 1,
    spaceBetween: 35,
    on: {
      slideChange: function () {
        const index_currentSlide = mainSwiper.realIndex;
        if (sliderNumberActive) {
          sliderNumberActive.innerHTML = "";
          sliderNumberActive.innerHTML = `${index_currentSlide + 1}`;
        }
      },
      init: function () {
        if (sliderNumberAll) {
          let allSlides = document.querySelectorAll('.main__slider .swiper-slide');
          sliderNumberAll.innerHTML = "";
          sliderNumberAll.innerHTML = ` / ${allSlides.length}`
        }
      }
    }
  });
  const NEXT = mainNav.querySelector('.next');
  const PREV = mainNav.querySelector('.prev');

  if (NEXT, PREV) {
    NEXT.addEventListener('click', () => {
      mainSwiper.slideNext();
    })
    PREV.addEventListener('click', () => {
      mainSwiper.slidePrev();
    })
  }

}




const mainActivitesSlider = document.querySelector('.main-activites__slider');
if (mainActivitesSlider) {
  const activitesNav = document.querySelector('.slider-nav--activites');
  const sliderNumberActive = activitesNav.querySelector('.slider-active');
  const sliderNumberAll = activitesNav.querySelector('.slider-all');

  let mainActivitesSwiper = new Swiper('.main-activites__slider', {

    modules: [Navigation, Pagination],
    loop: false,
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 35,
    on: {
      slideChange: function () {
        const index_currentSlide = mainActivitesSwiper.realIndex;
        let aaaa = mainActivitesSwiper.activeIndex;
        console.log(aaaa);
        if (sliderNumberActive) {
          sliderNumberActive.innerHTML = "";
          sliderNumberActive.innerHTML = `${index_currentSlide + 1}`;
        }
      },
      init: function () {
        if (sliderNumberAll) {
          let allSlides = mainActivitesSlider.querySelectorAll('.swiper-slide');
          sliderNumberAll.innerHTML = "";
          sliderNumberAll.innerHTML = ` / ${allSlides.length / 3}`
        }
      }
    },
    breakpoints: {
      768: {
        slidesPerView: 3,
        slidesPerGroup: 3,
      },
      500: {
        slidesPerView: 2,
        slidesPerGroup: 2,
      },
    }
  });
  const NEXT = activitesNav.querySelector('.next');
  const PREV = activitesNav.querySelector('.prev');

  if (NEXT, PREV) {
    NEXT.addEventListener('click', () => {
      mainActivitesSwiper.slideNext();
    })
    PREV.addEventListener('click', () => {
      mainActivitesSwiper.slidePrev();
    })
  }

}
